@import "../rgbstyle/rgbstyle-mixins";
@import "../rgbstyle/rgbstyle-variables";

.el-home-lista-perguntas {
    background-color: #f7f7f7;
    padding: 50px 0;
    @include desktop {
        padding: 100px 0;
        margin-top: -50px;
    }

    .introducao {
        .titulo {
            margin-bottom: 20px;
            width: 100%;
            display: block;
            font-size: 20px;
            color: $color_destaque;
            text-align: center;
            text-transform: uppercase;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 200;
            color: $color;

            b, strong {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                color: $color_destaque;
                width: 100%;
                display: block;
                font-size: 40px;
            }
        }
    }

    .itens {
        @include desktop {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .slick-list {
            margin: 0;
        }

        @include desktop {
            .slick-slide {
                margin: 22px 10px 35px 10px;
            }
            .slick-list {
                margin: 0;
            }
        }

        .item {

            @include desktop {
                width: 100%;
            }

            .chamada {
                @include desktop {
                    margin: 0;
                    overflow: hidden;
                }
            }

            .info {
                background: #fff;
                margin-bottom: 15px;
                @include desktop-hd {
                    padding: -moz-calc(20 / 1920 * 100vw) -moz-calc(20 / 1920 * 100vw);
                    padding: calc(20 / 1920 * 100vw) calc(20 / 1920 * 100vw) !important;
                }

                .titulo-container {
                    min-height: 30px;
                    @include mq-min(1025) {
                        min-height: 74px;
                    }
                }

                &::after {
                    border-color: #fff transparent transparent transparent;
                }
            }

        }

        .slick-arrow {
            position: absolute;
            display: flex;
            margin: 0 auto;
            transform: translateY(-50%);

            &.slick-prev {
                font-size: 15px;
                text-align: left;
                color: $color;
                z-index: 1;
                cursor: pointer;
                bottom: 0px;
                @include animado;

                &::after {
                    width: 45px;
                    height: 0px;
                    content: '';
                    border: 2px solid rgba(#757575, 1);
                    margin: 5px 0 0 -7px;
                }

                &:hover {
                    color: $color_destaque;

                    &::after {
                        border: 2px solid $color_destaque;
                    }
                }

                left: calc(50% - 60px);

            }

            &.slick-next {
                font-size: 16px;
                text-align: right;
                color: $color;
                z-index: 1;
                cursor: pointer;
                bottom: 0px;
                @include animado;

                &::before {
                    width: 45px;
                    height: 0px;
                    content: '';
                    border: 2px solid rgba(#757575, 1);
                    margin: 6px -7px 0 0;
                }

                &:hover {
                    color: $color_destaque;

                    &::before {
                        border: 2px solid $color_destaque;
                    }
                }

                right: calc(50% - 60px);
                margin: -1px auto;
            }
        }
    }

    .center {
        width: 100%;
        display: flex;
        margin-top: 5px;
        text-align: center;

        a.btn {
            padding: 10px 35px;
            border: 1px solid #8f8f8f;
            z-index: 0;
            width: auto;
            display: block;
            margin: 0 auto;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            text-transform: uppercase;

            &:hover {
                color: #ffffff;
                background-color: $color_destaque;
            }
        }
    }


}